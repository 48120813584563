import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const enterAnimation: string = '180ms ease-out';
const leaveAnimation: string = '150ms ease-in';

export const fadeInOutTop: AnimationTriggerMetadata = trigger('fadeInOutTop', [
  transition(':enter', [
    style({
      opacity: '0',
      transform: 'translate3d(0, 20%, 0)',
    }),
    animate(
      enterAnimation,
      style({
        opacity: '1',
        transform: 'translate3d(0, 0, 0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: '1',
      transform: 'translate3d(0, 0, 0)',
    }),
    animate(
      leaveAnimation,
      style({
        opacity: '0',
        transform: 'translate3d(0, 20%, 0)',
      })
    ),
  ]),
]);
