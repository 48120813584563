<button
  (click)="close()"
  *ngIf="showToast$ | async as toast"
  @fadeInOutTop
  [class.error]="toast.type === 'error'"
  [class.info]="toast.type === 'info'"
  [class.success]="toast.type === 'success'"
  [class.warning]="toast.type === 'warning'"
  class="toast d-flex text-start d-print-none"
>
  <span *ngIf="toast.type === 'success'" class="icon">
    <svg
      fill="currentColor"
      focusable="false"
      height="20"
      role="img"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M8,15l-5-5l1.4-1.4L8,12.2l7.6-7.6L17,6L8,15z"
      />
    </svg>
  </span>
  <span *ngIf="toast.type === 'error'" class="icon">
    <svg
      fill="currentColor"
      focusable="false"
      height="20"
      role="img"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M15,13.6L13.6,15L10,11.4L6.4,15L5,13.6L8.6,10L5,6.4L6.4,5
	L10,8.6L13.6,5L15,6.4L11.4,10L15,13.6z"
      />
    </svg>
  </span>
  <span *ngIf="toast.type === 'info'" class="icon">
    <svg
      fill="currentColor"
      focusable="false"
      height="20"
      role="img"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M11,15H9V9h2V15z M11,7H9V5h2V7z" />
    </svg>
  </span>
  <span *ngIf="toast.type === 'warning'" class="icon">
    <svg
      fill="currentColor"
      focusable="false"
      height="20"
      role="img"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0,19h22L11,0L0,19z M12,16h-2v-2h2V16z M12,12h-2V8h2V12z" />
    </svg>
  </span>

  <span class="d-block">
    {{ toast.message }}
  </span>

  <button class="btn close p-0 d-flex" type="button">
    <svg
      fill="currentColor"
      focusable="false"
      height="14"
      role="img"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14,1.4L12.6,0L7,5.6L1.4,0L0,1.4L5.6,7L0,12.6L1.4,14L7,8.4l5.6,5.6l1.4-1.4L8.4,7L14,1.4z" />
    </svg>
  </button>
</button>
